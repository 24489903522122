@import "./helpers/componentHeader";

// 45260
// 168198
.modal {
    &.mqlPopUp {
		.modal-dialog {
	        .modal-title {
	            color: $black;
	            font-size: 18px;
	
	            &::after{
	                content: ":";
	                position: absolute;
	                margin-left: 0;
	            }
	        }
			// 48369
			.modal-header {
				padding: 14px;
				.close {
					font-family: $primary-font;
					position: absolute;
					padding: 14px;
					font-weight: normal;
					color: $primary;
					opacity: 1;
					right: 32px;
					top: 24px;
					z-index: 1;
					font-size: 26px;
					// 41626
					@include media-breakpoint-down(sm) {
						font-weight: bold;
						top: 16px;
					}
				}
			}
			//48910
	        .model-body {
	            font-size: 16px;
				font-family: $primary-font; 
				line-height: 24px;
				color: $black;
				padding-bottom: 28px;
				margin: 0px 20px 0px 40px;
				
					.banner-image {
						width:100%;
						min-height: 470px;
					}
					
					.model-col-right {
						@include media-breakpoint-up(lg) {
							max-height: 470px;
							overflow-y: auto;
						}
					}
					
					.container-model {
						padding: 0px 12px 0px 20px;
						// 41626
						@include media-breakpoint-down(sm) {
							padding: 0px 20px;
						}
					}
					// 41626
					.header-logo {
						@include media-breakpoint-down(sm) {
							max-height: 30px;
						}
					}
					//48657
					.model-title {
						font-size: 24px;
						font-family: $primary-font-semibold; 
						line-height: 36px;
						color: $black;
						margin: 25px 0 16px 0;
						font-weight: 500;
						
						@include media-breakpoint-down(md) {
							font-size: 18px;
							line-height: 24px;
						}
					
					&.title-2 {
						line-height: 24px;
						font-family: $primary-font-light;
						font-weight: 300;
						// 41626
						@include media-breakpoint-down(md) {
							font-size: 16px;
							margin-top:16px;
						}
					}
				}
				// 48657
				.form-email {
					font-size: 14px;
					min-height: 38px;			
				}
				
				.section-title-text {
					margin-bottom:30px;
					font-size: 16px;
					// 41626
					@include media-breakpoint-down(md) {
						font-size: 14px;
						margin-bottom: 16px;
					}
				}
				
				// 38477 - mql integration work
            	.form-error__message {
                	color: $primary !important;
                	font-size: 13px;
            	}
                // 69356
				.privacy-link {
					margin-top: 30px;
					color: $black;
				    font-family: $primary-font-italic;
                    font-style: italic;
				    font-size: 12px;
				    line-height: 18px;
				    text-align: left;
				}
	
	            a {
				    font-family: $primary-font-italic;
                    font-style: italic;
				    font-size: 12px;
	                color: $primary !important;
	                text-decoration: underline;
	            }
	
				@include media-breakpoint-down(md) {
					margin: 0px 16px;
				}
	        }
			// 48371
	        ul {
				&.list-model-text {
					font-family: $primary-font;
					font-size: 12px;
					line-height: 20px;
		            padding: 0 40px 0px 60px;
		            list-style: none;
		            text-align: left;
		            color: $black;
					margin-top: 55px;
					
					li {
						&:before {
						    content: "\2022";
						    color: $primary;
						    font-weight: bold;
						    display: inline-block;
						    width: 1em;
							margin-left: -1em;
						}
					}
					
					@include media-breakpoint-down(md) {
						padding: 0px 0px 0px 26px;
						max-width: 270px;
						margin: 0px auto;
					}
				}
	        }
			&.modal-lg{
		   		@include media-breakpoint-up(lg) {
					max-width: 920px;
					margin-top: 7%;
				}
			}

			@include media-breakpoint-down(md) {
	        	width: 100%;
	            height: 100%;
	            margin: 0;
	            padding: 0;
	            max-width: 100%;

	            .modal-content {
	            	height: auto;
	                min-height: 100%;
	                border-radius: 0;
	                border: 0px;
	            }
			}
			// 48371
		}
	}
}
// 45260